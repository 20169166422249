import { css } from "@emotion/react"
import { colors } from "@mkt/ccbr/src/styles/Tokens"
import { below } from "@mkt/ui/styles/Breakpoints"

export const footer = css`
  background-color: ${colors.gray.dark};
  color: ${colors.white};

  .footer-lp__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: no-wrap;
    padding: 21px 52px;
    width: 100%;
    ${below["l"]} {
      flex-direction: column;
    }
  }

  .footer-lp__column {
    width: 100%;

    &:nth-of-type(1) {
      display: flex;
      align-items: center;
      gap: 42px;
      
      ${below["l"]} {
        flex-direction: column;
        gap: 21px;
        margin-bottom: 21px;
      }
    }

    &:nth-of-type(3) {
      text-align: right;
      ${below["l"]} {
        text-align: center;
      }
    }

    .footer-lp__logo {
      margin: 18px 0px;

      ${below["l"]} {
        text-align: left;

        .footer-lp__logo--img {
          width: 122px;
        }
      }
    }
  }

  .footer-lp__text {
    line-height: 18px;
    font-size: 14px;
    font-weight: 300;

    .footer-lp__text--link {
      color: ${colors.white};
      display: block;
      font-weight: 700;
      margin: 14px 0px;
      text-decoration: none;
    }

    span {
      display: block;
    }
  }

  .footer-lp__links {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    ${below["l"]} {
      justify-content: center;
    }
  }
`
